import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {home, send, cog} from 'ionicons/icons';
import Home from './pages/Home';
import FundTransfer from './pages/FundTransfer';
import Settings from './pages/Settings';
import Login from "./pages/Login";
import QRmenu from "./pages/QRmenu";
import QRmenuLogin from "./pages/QRmenuLogin";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const Auth = () => {
  if(JSON.parse(localStorage.getItem('login') as string)){
    return (
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/home" component={Home} exact={true}/>
            <Route path="/fund-transfer" component={FundTransfer} exact={true}/>
            <Route path="/settings" component={Settings}/>
            <Route path="/qr-login" component={QRmenuLogin} exact={true}/>
            <Route path="/" render={() => <Redirect to="/home"/>} exact={true}/>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="Home" href="/home">
              <IonIcon icon={home}/>
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Fund Transfer" href="/fund-transfer">
              <IonIcon icon={send}/>
              <IonLabel>Fund Transfer</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Settings" href="/settings">
              <IonIcon icon={cog}/>
              <IonLabel>Settings</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
    )
  } else {
    return (
        <Route
            render={function () {
              return <Redirect to="/login"/>;
            }}
        />
    )
  }
}
const App: React.FC = () => (
    <IonApp>
      <IonReactRouter>
        {Auth()}
        <Route path="/login" component={Login} exact={true}/>
        <Route path="/qr" component={QRmenu} exact={true}/>
      </IonReactRouter>
    </IonApp>
);

export default App;
