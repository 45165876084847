import axios from "../../../axios-config";
import * as actionTypes from "./actionTypes";

export const accBal = (data) => {
    return {
        type: actionTypes.ACCOUNT_BALANCE,
        data: data
    };
};

export const transactionHistory = (data) => {
    return {
        type: actionTypes.TRANSACTION_HISTORY,
        data: data
    };
};

export const tosRead = (data) => {
    return {
        type: actionTypes.TOS_READ,
        data: data
    };
};

export const tosReadAgree = (data) => {
    return {
        type: actionTypes.TOS_READ_AGREE,
        data: data
    };
};

export const AccBal = (data) => {
    return dispatch => {
        axios.get( 'balance', data)
            .then( response => {
                dispatch(accBal(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const TransactionHistory = (page) => {
    return dispatch => {
        axios.get( 'transaction-history?page=' + page)
            .then( response => {
                dispatch(transactionHistory(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const TosRead = (page) => {
    return dispatch => {
        axios.get( 'tos')
            .then( response => {
                dispatch(tosRead(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const TosReadAgree = (page) => {
    return dispatch => {
        axios.get( 'agree')
            .then( response => {
                dispatch(tosReadAgree(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};


