import * as actionTypes from "./actionTypes";
import { updateObject } from '../../helpers/helpers';
const initialState = {
    resMessage: [],
    profileData: [],
};

const profileData = (state, action) => {
    return updateObject( state, {
        profileData: action.data,
    });
};

const resMessage = (state, action) => {
    return updateObject( state, {
        resMessage: action.data.message
    });
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE:  return profileData(state, action);
        case actionTypes.CHANGE_PASSWORD:  return resMessage(state, action);
        default:
            return state;
    }
};

export default reducer;