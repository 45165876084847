import React, {Component } from 'react';
import {
    IonContent, IonHeader, IonPage, IonRow,
    IonTitle, IonToolbar, IonCol, IonGrid, IonItem, IonList, IonLabel, IonImg,
    IonInput, IonButton, IonText, IonToast
} from '@ionic/react';
import SimpleReactValidator from "simple-react-validator";
import {connect} from 'react-redux';
import * as actions from "../services/store/auth/actions";
// import { Plugins } from "@capacitor/core";

// const { Geolocation} = Plugins;

interface myState {
    isSignedIn: boolean,
    phone_number: string,
    password: string,
    resMessage: object[],
    toast: boolean
}

class Login extends Component<any, myState> {
    private validator: SimpleReactValidator;
    constructor(props: any) {
        super(props);
        this.state = {
            isSignedIn: false,
            phone_number: '',
            password: '',
            resMessage: [],
            toast: true
        }

        this.validator = new SimpleReactValidator({
            element: (message: any) => <IonText color="danger">{message}</IonText>
        })

    }

    componentDidMount = () => {
        if (JSON.parse(localStorage.getItem('login'))) {
            window.location.replace("/home");
        }
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<myState>, snapshot?: any): void {
        if(prevProps.resMessage !== this.props.resMessage) {
            this.setState({
                toast: true,
                resMessage: this.props.resMessage
            })
        }

        if(prevProps.token !== this.props.token){
            if (this.props.token.access_token) {
                localStorage.setItem('login', JSON.stringify(this.props.token))
                window.location.replace("/home");
            }
        }
    }



    submitHandler = (e: any) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.Login(this.state);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    phoneNumberChange = (event: any) => {
        this.setState({phone_number: event.detail.value});
    };

    passwordChange = (event: any) => {
        this.setState({password: event.detail.value});
    };

    // async getCurrentPosition() {
    //     const coordinates = await Geolocation.getCurrentPosition();
    //     console.log('Current', coordinates);
    // }
    //
    // watchPosition() {
    //     const wait = Geolocation.watchPosition({}, (position, err) => {
    //         console.log(wait)
    //     })
    // }

     render() {
        this.validator.purgeFields();

        const errorMessage = () => {
            let data = this.state.resMessage;
            let res = '';
            let length = data ? data.length : 0;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    res += "<li>" + data[i] + "</li>"
                }
                return <IonToast
                    isOpen={this.state.toast}
                    color="danger"
                    position="top"
                    onDidDismiss={() => this.setState({toast: false})}
                    message={res}
                    duration={5000}
                />;
            }
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Log In</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {errorMessage()}
                    <IonGrid>
                        <form onSubmit={this.submitHandler}>
                            <IonRow>
                                <IonCol className="ion-margin-top ion-margin-bottom">
                                    <IonImg src="./assets/logo.png" style={{width: "70%", margin: "0 auto"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList className="ion-margin-top ion-margin-bottom">
                                        <IonItem>
                                            <IonLabel position="floating">Phone Number</IonLabel>
                                            <IonInput type="number" onIonChange={this.phoneNumberChange}
                                                      value={this.state.phone_number}></IonInput>
                                        </IonItem>
                                        {this.validator.message('Phone Number', this.state.phone_number, 'required|numeric')}
                                        <IonItem>
                                            <IonLabel position="floating">Password</IonLabel>
                                            <IonInput type="password" onIonChange={this.passwordChange}
                                                      value={this.state.password}></IonInput>
                                        </IonItem>
                                        {this.validator.message('Password', this.state.password, 'required')}
                                    </IonList>
                                    <div className="ion-margin-top ion-margin-bottom">
                                        <IonButton expand="block" type="submit">Login</IonButton>
                                    </div>
                                    <div className="ion-margin-top ion-margin-bottom">
                                        <IonButton expand="block" color="warning" routerLink="/qr">Transfer via QR Code</IonButton>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </form>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        token: state.auth.token,
        resMessage: state.auth.resMessage
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        Login: (data: any) => dispatch(actions.Login(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
