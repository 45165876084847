import React, {Component} from 'react';
import {
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
    IonModal, IonItem, IonLabel, IonInput, IonCard, IonCardContent, IonList, IonButton, IonButtons,
    IonGrid, IonRow, IonCol, IonAvatar, IonCardTitle, IonText, IonCardSubtitle, IonToast
} from '@ionic/react';
import SimpleReactValidator from "simple-react-validator";
import {connect} from 'react-redux';
import * as actions from "../services/store/fundtransfer/actions";
import NumberFormat from 'react-number-format';
import { SmsRetriever } from '@ionic-native/sms-retriever';
interface myState {
    setShowModal: boolean,
    phone_number: string,
    amount: string,
    toast: boolean,
    toast_success: boolean,
    resMessage: object[],
    recipientDetails: {
        customer_name: string,
        image: string
    },
    otp: string,
    send_otp: boolean,
    app_hash: string
}

class FundTransfer extends Component<any, myState> {
    private validator: SimpleReactValidator;
    private baseState: any;
    private smsRetriever: any
    constructor(props: any) {
        super(props);
        this.state = {
            setShowModal: false,
            toast: false,
            toast_success: false,
            phone_number: localStorage.getItem('pn') ? localStorage.getItem('pn') : '',
            amount: '',
            resMessage: [],
            recipientDetails: {
                customer_name: '',
                image: ''
            },
            otp: '',
            send_otp: false,
            app_hash: ''
        }

        this.baseState = this.state;
        this.smsRetriever = SmsRetriever;

        this.validator = new SimpleReactValidator({
            element: (message: any) => <IonText color="danger">{message}</IonText>
        })
    }

    componentDidMount(): void {
        this.smsRetriever.getAppHash()
            .then((res: any) => {
                this.setState({
                    app_hash: res
                })
            })
            .catch((error: any) => console.error(error));
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<myState>, snapshot?: any): void {
        if (prevProps.recipientDetails !== this.props.recipientDetails) {
            if (typeof this.props.recipientDetails != 'undefined' || this.props.recipientDetails != null) {
                this.setState({
                    recipientDetails: this.props.recipientDetails,
                    setShowModal: true
                })
            }
        }

        if (prevProps.resMessage !== this.props.resMessage) {
            if (this.props.resMessage) {
                if (this.props.resMessage[0] === "OTP successfully Sent") {
                    this.setState({
                        toast_success: true,
                        send_otp: true,
                        resMessage: this.props.resMessage
                    })
                } else if (this.props.resMessage[0] === "Wallet Successfully Sent") {
                    localStorage.removeItem('pn');
                    this.setState({
                        toast_success: true,
                        send_otp: true,
                        resMessage: this.props.resMessage,
                    })
                    setTimeout(() => {
                        this.setState(this.baseState);
                    },4000)
                } else {
                    this.setState({
                        toast: true,
                        resMessage: this.props.resMessage
                    })
                }
            }
        }
    }

    checkRecipient = (e: any) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.CheckAccount(this.state)
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    sendOTP = (e: any) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.smsRetriever.startWatching()
                .then((res: any) =>
                {
                    this.setState({
                        otp: res.Message.substring(0,6)
                    })
                }).catch((error: any) => console.error(error));
            this.props.SendOTP(this.state);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    sendWallet = (e: any) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.SendWallet(this.state);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    phoneNumberChange = (event: any) => {
        this.setState({phone_number: event.detail.value});
    };

    amountChange = (event: any) => {
        this.setState({amount: event.detail.value});
    };

    otpChange = (event: any) => {
        this.setState({otp: event.detail.value});
    };

    render() {
        this.validator.purgeFields();

        const errorMessage = () => {
            let data = this.state.resMessage;
            let res = '';
            let length = data ? data.length : 0;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    res += "<li>" + data[i] + "</li>"
                }
                return <IonToast
                    isOpen={this.state.toast}
                    color="danger"
                    position="top"
                    onDidDismiss={() => this.setState({toast: false})}
                    message={res}
                    duration={5000}
                />;
            }
        }

        const successMessage = () => {
            let data = this.state.resMessage;
            let res = '';
            let length = data ? data.length : 0;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    res += "<li>" + data[i] + "</li>"
                }
                return <IonToast
                    isOpen={this.state.toast_success}
                    color="success"
                    position="top"
                    onDidDismiss={() => this.setState({toast_success: false})}
                    message={res}
                    duration={5000}
                />;
            }
        }

        const closeForm = () => {
            localStorage.removeItem('pn')
            this.setState({
                ...this.baseState,
                phone_number: ''
            })
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Fund Transfer</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {successMessage()}
                    {errorMessage()}
                    <IonCard>
                        <IonCardContent>
                            <form onSubmit={this.checkRecipient}>
                                <IonList>
                                    <IonItem>
                                        <IonLabel position="floating">Enter Recipient Phone
                                            Number </IonLabel>
                                        <IonInput type="number" onIonChange={this.phoneNumberChange}
                                                  value={this.state.phone_number}></IonInput>
                                    </IonItem>
                                    {this.validator.message('Phone Number', this.state.phone_number, 'required|numeric')}
                                    <IonItem>
                                        <IonLabel position="floating">Wallet Amount</IonLabel>
                                        <IonInput type="number" onIonChange={this.amountChange}
                                                  value={this.state.amount}></IonInput>
                                    </IonItem>
                                    {this.validator.message('Phone Number', this.state.amount, 'required|numeric')}
                                </IonList>
                                <div className="ion-margin-top ion-margin-bottom">
                                    <IonButton expand="block" type="submit" color="primary">Proceed</IonButton>
                                </div>
                                <div className="ion-margin-top ion-margin-bottom">
                                    <IonButton expand="block" type="button" color="warning" routerLink="/qr-login">Scan QR Code</IonButton>
                                </div>
                                {this.state.phone_number !== '' ?
                                    <div className="ion-margin-top ion-margin-bottom">
                                        <IonButton expand="block" color="danger" type="reset" onClick={()=>closeForm()}>Cancel</IonButton>
                                    </div>
                                    :
                                    ''
                                }
                            </form>
                        </IonCardContent>
                    </IonCard>

                    <IonModal isOpen={this.state.setShowModal}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Confirm Transfer</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={()=>closeForm()}>Close</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonGrid>
                                {this.state.send_otp === false ?
                                    <IonRow>
                                        <IonCol className="ion-text-center ion-margin-top">
                                            <IonAvatar style={{margin: "0 auto", width: "100px", height: "100px"}}>
                                                <img alt="profile_image"
                                                    src={this.state.recipientDetails.image}/>
                                            </IonAvatar>
                                            <br/><br/>
                                            <IonCardTitle>{this.state.recipientDetails.customer_name}</IonCardTitle>
                                            <IonCardSubtitle>Recipient Account Name</IonCardSubtitle>
                                            <br/>
                                            <IonCardTitle>{this.state.phone_number}</IonCardTitle>
                                            <IonCardSubtitle>Phone Number</IonCardSubtitle>
                                            <br/><br/>
                                            <IonCardTitle>
                                                <IonText color="danger" style={{fontSize: "50px"}}>
                                                    <NumberFormat value={this.state.amount} displayType={'text'}
                                                                  thousandSeparator={true} prefix={''}/>
                                                </IonText>
                                            </IonCardTitle>
                                            <IonCardSubtitle>Wallet Amount</IonCardSubtitle>

                                        </IonCol>
                                    </IonRow>
                                    :
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem>
                                                <IonLabel position="floating">Enter One Time
                                                    Password</IonLabel>
                                                <IonInput type="number" onIonChange={this.otpChange}
                                                          value={this.state.otp}></IonInput>
                                            </IonItem>
                                            {this.validator.message('One Time Password', this.state.otp, 'required|numeric')}
                                        </IonCardContent>
                                    </IonCard>
                                }
                            </IonGrid>

                        </IonContent>
                        {this.state.send_otp === false ?
                            <IonButton onClick={this.sendOTP}>Confirm</IonButton>
                            :
                            <IonButton onClick={this.sendWallet} color="warning">Send</IonButton>
                        }

                    </IonModal>

                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        recipientDetails: state.fundtransfer.recipientDetails,
        resMessage: state.fundtransfer.resMessage
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CheckAccount: (data: any) => dispatch(actions.CheckAccount(data)),
        SendOTP: (data: any) => dispatch(actions.SendOTP(data)),
        SendWallet: (data: any) => dispatch(actions.SendWallet(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundTransfer);
