import React, {Component, RefObject} from 'react';
import {
    IonContent, IonHeader, IonPage, IonRow,
    IonTitle, IonToolbar, IonCol, IonGrid,
    IonButton, IonText, IonToast, IonButtons
} from '@ionic/react';
import SimpleReactValidator from "simple-react-validator";
import {connect} from 'react-redux';
import * as actions from "../services/store/auth/actions";
import {BarcodeScanner} from "@ionic-native/barcode-scanner";
import {isAndroid, isBrowser, isIOS} from "react-device-detect";

interface myState {
    isSignedIn: boolean,
    phone_number: string,
    password: string,
    resMessage: object[],
    toast: boolean
}

class QRmenuLogin extends Component<any, myState> {
    private validator: SimpleReactValidator;
    private baseState: any;

    private readonly inputOpenFileRef : RefObject<HTMLInputElement>
    constructor(props: any) {

        super(props);
        this.state = {
            isSignedIn: false,
            phone_number: '',
            password: '',
            resMessage: [],
            toast: true,
        }

        this.baseState = this.state;
        this.inputOpenFileRef = React.createRef()
        this.validator = new SimpleReactValidator({
            element: (message: any) => <IonText color="danger">{message}</IonText>
        })
    }

    showOpenFileDlg = () => {
        this.inputOpenFileRef.current.click()
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<myState>, snapshot?: any): void {
        if(prevProps.resMessage !== this.props.resMessage) {
            this.setState({
                toast: true,
                resMessage: this.props.resMessage
            })
        }

        if(prevProps.phone_number !== this.props.phone_number){
            if(typeof this.props.phone_number !== "undefined"){
                localStorage.setItem('pn', this.props.phone_number)
                this.setState(this.baseState)
                window.location.replace("/fund-transfer");
            }
        }
    }

    submitHandler = (e: any) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.Login(this.state);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    phoneNumberChange = (event: any) => {
        this.setState({phone_number: event.detail.value});
    };

    passwordChange = (event: any) => {
        this.setState({password: event.detail.value});
    };

    handleFileuploadChange = (event:any) => {
        if (event.target.files[0]) {
            this.props.QRImage(event.target.files[0])
        }
    }

    render() {
        console.log(isBrowser)
        const openScanner = async () => {
            const data = await BarcodeScanner.scan();
            this.props.QRCam({
                qr_code : data.text
            })
        };

        const cancelTransaction = () => {
            this.setState(this.baseState);
            localStorage.removeItem('pn');
            this.props.history.push('/fund-transfer')
        }

        const errorMessage = () => {
            let data = this.state.resMessage;
            let res = '';
            let length = data ? data.length : 0;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    res += "<li>" + data[i] + "</li>"
                }
                return <IonToast
                    isOpen={this.state.toast}
                    color="danger"
                    position="top"
                    onDidDismiss={() => this.setState({toast: false})}
                    message={res}
                    duration={5000}
                />;
            }
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Send via QR Code</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => cancelTransaction()}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {errorMessage()}
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <div className="ion-margin-top ion-margin-bottom">
                                    {isBrowser === false && isAndroid === false && isIOS === false  ? <IonButton expand="block" onClick={openScanner}>Scan QR Code via Phone Camera</IonButton> : ''}
                                </div>
                                <div className="ion-margin-top ion-margin-bottom">
                                    <IonButton expand="block" type="button" onClick={this.showOpenFileDlg}>Import QR Code Image from Storage</IonButton>
                                </div>
                            </IonCol>
                        </IonRow>
                        <input type="file" ref={this.inputOpenFileRef} onChange={this.handleFileuploadChange} accept='image/jpeg,image/x-png' style={{display:"none"}}/>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        token: state.auth.token,
        phone_number: state.auth.phone_number,
        resMessage: state.auth.resMessage
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        QRCam: (data: any) => dispatch(actions.QRCam(data)),
        Login: (data: any) => dispatch(actions.Login(data)),
        QRImage: (data: any) => dispatch(actions.QRImage(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRmenuLogin);
