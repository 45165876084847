import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import {Provider} from "react-redux";
import thunk from 'redux-thunk';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import AuthReducer from "./services/store/auth/auth";
import HomeReducer from "./services/store/home/home";
import FundTransferReducer from "./services/store/fundtransfer/fundtransfer";
import ProfileReducer from "./services/store/profile/profile";

const rootReducer = combineReducers({
    auth: AuthReducer,
    home: HomeReducer,
    fundtransfer: FundTransferReducer,
    profile: ProfileReducer
});

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk),
));

const app = (
    <Provider store={store}>
        <App/>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
