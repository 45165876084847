import * as actionTypes from "./actionTypes";
import { updateObject } from '../../helpers/helpers';
const initialState = {
    resMessage: [],
    token: [],
    phone_number: ''
};

const token = (state, action) => {
    return updateObject( state, {
        token: action.data,
        resMessage: action.data.message,
    });
};

const getPhoneNumber = (state, action) => {
    return updateObject( state, {
        token: action.data,
        phone_number: action.data.phone_number,
        resMessage: action.data.message,
    });
};

const resMessage = (state, action) => {
    return updateObject( state, {
        resMessage: action.data.message
    });
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.LOGIN:  return token(state, action);
        case actionTypes.LOGOUT:  return resMessage(state, action);
        case actionTypes.QR_CAM:  return getPhoneNumber(state, action);
        case actionTypes.QR_IMAGE:  return getPhoneNumber(state, action);
        default:
            return state;
    }
};

export default reducer;