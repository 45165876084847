import React, {Component} from 'react';
import {
    IonContent, IonHeader, IonPage, IonRow,
    IonTitle, IonToolbar, IonCol, IonGrid, IonCard, IonInfiniteScroll, IonInfiniteScrollContent,
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonRefresherContent,IonCheckbox,
    IonCardContent, IonItem, IonList, IonRefresher, IonImg, IonListHeader, IonText, IonModal, IonButton, IonLabel
} from '@ionic/react';
import * as actions from "../services/store/home/actions";
import {connect} from "react-redux";
import Moment from 'react-moment';
import {RefresherEventDetail} from "@ionic/core";
import parse from 'html-react-parser';
import {isBrowser} from "react-device-detect";

interface myState {
    accountBalance: {
        account_name: string
        wallet_balance: number,
        points_balance: number,
    },
    transactionHistory: {
        page: number,
        total_page: number,
        result: any
    },
    agree: boolean,
    isRead: boolean
}

class Home extends Component<any, myState> {
    private baseState: any;

    constructor(props: any) {
        super(props);
        this.state = {
            accountBalance: {
                account_name: '',
                wallet_balance: 0,
                points_balance: 0,
            },
            transactionHistory: {
                page: 1,
                total_page: 0,
                result: []
            },
            agree: false,
            isRead: false
        }

        this.baseState = this.state;
    }

    componentDidMount(): void {
        this.props.TosRead();
        this.props.AccBal();
        this.props.TransactionHistory(this.state.transactionHistory['page']);
    }

    refreshInfo = (event: CustomEvent<RefresherEventDetail>) => {
        this.setState(this.baseState);
        setTimeout(() => {
            this.props.AccBal();
            this.props.TransactionHistory(this.state.transactionHistory['page']);
            event.detail.complete();
        }, 1000);
    }

    showMoreTransaction = (e: any, method: any = '') => {
        if (this.state.transactionHistory['page'] !== this.state.transactionHistory['total_page']) {
            setTimeout(() => {
                this.props.TransactionHistory(this.state.transactionHistory['page'] + 1);
                if(method !== 'manual'){
                    e.target.complete();
                }
            }, 500);
        } else {
            e.target.complete();
        }

    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.accountBalance !== this.props.accountBalance) {
            this.setState({
                accountBalance: this.props.accountBalance
            })
        }

        if (prevProps.transactionHistory !== this.props.transactionHistory) {
            if (this.props.transactionHistory.page === 1) {
                this.setState({
                    transactionHistory: this.props.transactionHistory
                })
            } else {
                if ((this.state.transactionHistory.total_page + 1) !== this.props.transactionHistory.page) {
                    this.setState({
                        transactionHistory: {
                            ...this.state.transactionHistory,
                            page: this.props.transactionHistory.page,
                            result: this.state.transactionHistory.result.concat(this.props.transactionHistory.result)
                        }
                    })
                }
            }
        }

        if(prevProps.isRead !== this.props.isRead) {
            this.setState({
                isRead: this.props.isRead
            })
        }
    }

    render() {
        console.log(this.state.transactionHistory)
        const tosModal = () => {
            return (
                <IonModal isOpen={this.state.isRead} >
                    <IonHeader>
                    <IonToolbar>
                        <IonTitle>Terms Of Service</IonTitle>
                    </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    {parse(this.props.tos)}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList lines="none">
                                        <IonItem>
                                            <IonLabel>
                                                I Agree.
                                            </IonLabel>
                                            <IonCheckbox slot="start" color="primary" onClick={ ()=>this.setState({
                                                agree: !this.state.agree
                                            })}/>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {this.state.agree ? <IonButton onClick={()=>this.props.TosReadAgree()} expand="block" shape="round">Continue</IonButton> : ''}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>
            );
        }

        const History = () => {
            let trans_count = this.state.transactionHistory.result.length;
            let res = [];
            for (let i = 0; i < trans_count; i++) {
                res.push(
                    <IonListHeader lines="inset" key={i}>
                        <h5>
                            <strong>
                                <Moment format="MMM DD, YYYY">
                                    {Date.parse(this.state.transactionHistory.result[i]['created_at'])}
                                </Moment>
                            </strong>
                        </h5>
                    </IonListHeader>
                )

                let data_count = this.state.transactionHistory.result[i]['data'].length;

                for (let e = 0; e < data_count; e++) {
                    res.push(
                        <IonItem key={i + 'trans-data-' + e}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        {this.state.transactionHistory.result[i]['data'][e]['title']} <br/>
                                        <span style={{fontSize:'8px', color: 'gray'}}>
                                            Transaction Time: {this.state.transactionHistory.result[i]['data'][e]['time']}
                                        </span>
                                        <br/>
                                        <IonCardSubtitle>
                                            {this.state.transactionHistory.result[i]['data'][e]['sub_title']}
                                        </IonCardSubtitle>
                                    </IonCol>
                                    <IonCol>
                                        <IonText>
                                            {parse(this.state.transactionHistory.result[i]['data'][e]['value'])}
                                        </IonText>
                                        <br/>
                                        <IonCardSubtitle>
                                            {this.state.transactionHistory.result[i]['data'][e]['value_desc']}
                                        </IonCardSubtitle>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                    )
                }

            }
            return res;
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Home</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {tosModal()}
                    <IonRefresher slot="fixed" onIonRefresh={this.refreshInfo}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>

                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-margin-top ion-margin-bottom">
                                <IonImg src="/assets/logo.png" style={{width: "70%", margin: "0 auto"}}/>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>{this.state.accountBalance.account_name}</IonCardTitle>
                            <IonCardSubtitle>FM Rewards Account</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow>

                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <strong>Wallet</strong>
                                        <h1>{this.state.accountBalance.wallet_balance}</h1>
                                    </IonCol>
                                    <IonCol>
                                        <strong>Rewards Points</strong>
                                        <h1>{this.state.accountBalance.points_balance}</h1>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                    <IonRow style={{paddingBottom:'20%'}}>
                        <IonCol >
                            <IonList lines="none">
                                <IonListHeader>Transaction History</IonListHeader>
                                {History()}
                            </IonList>
                        </IonCol>
                    </IonRow>

                    {
                        this.state.transactionHistory['page'] !== this.state.transactionHistory['total_page'] && this.state.transactionHistory['page'] <= 3 ?
                            <IonRow>
                                <IonCol class="ion-text-center" size="12">
                                    <IonButton color="primary" size="small" onClick={(e)=>this.showMoreTransaction(e, 'manual')}>Load More...</IonButton>
                                </IonCol>
                            </IonRow> : ''
                    }

                    <IonInfiniteScroll threshold="1px"
                                       position="bottom"
                                       onIonInfinite={this.showMoreTransaction}>

                        <IonInfiniteScrollContent loadingSpinner="bubbles"
                                                  loadingText="Loading more data...">

                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        accountBalance: state.home.accountBalance,
        transactionHistory: state.home.transactionHistory,
        isRead: state.home.tosData.is_read,
        tos: state.home.tosData.tos,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        AccBal: () => dispatch(actions.AccBal()),
        TransactionHistory: (page: number) => dispatch(actions.TransactionHistory(page)),
        TosRead: () => dispatch(actions.TosRead()),
        TosReadAgree: () => dispatch(actions.TosReadAgree())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
