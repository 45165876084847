import * as actionTypes from "./actionTypes";
import { updateObject } from '../../helpers/helpers';
const initialState = {
    accountBalance: [],
    transactionHistory:[],
    tosData: {
        is_read: false,
        tos: ""
    }
};

const AccBal = (state, action) => {
    return updateObject( state, {
        accountBalance: action.data,
    });
};

const TransHistory = (state, action) => {
    return updateObject( state, {
        transactionHistory: action.data,
    });
};

const TosRead = (state, action) => {
    return updateObject( state, {
        tosData: action.data,
    });
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_BALANCE:  return AccBal(state, action);
        case actionTypes.TRANSACTION_HISTORY:  return TransHistory(state, action);
        case actionTypes.TOS_READ:  return TosRead(state, action);
        case actionTypes.TOS_READ_AGREE:  return TosRead(state, action);
        default:
            return state;
    }
};

export default reducer;