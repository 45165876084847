import * as actionTypes from "./actionTypes";
import { updateObject } from '../../helpers/helpers';
const initialState = {
    resMessage: [],
    recipientDetails: []
};

const checkAcc = (state, action) => {
    return updateObject( state, {
        recipientDetails: action.data.profile_info,
        resMessage: action.data.message
    });
};

const resMessage = (state, action) => {
    return updateObject( state, {
        resMessage: action.data.message
    });
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.CHECK_ACCOUNT:  return checkAcc(state, action);
        case actionTypes.SEND_OTP:  return resMessage(state, action);
        case actionTypes.SEND_WALLET:  return resMessage(state, action);
        default:
            return state;
    }
};

export default reducer;