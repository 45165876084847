import axios from "../../../axios-config";
import * as actionTypes from "./actionTypes";

export const changePass = (data) => {
    return {
        type: actionTypes.CHANGE_PASSWORD,
        data: data
    };
};

export const getProfile = (data) => {
    return {
        type: actionTypes.GET_PROFILE,
        data: data
    };
};

export const ChangePass = (data) => {
    return dispatch => {
        axios.post( 'changepass', data)
            .then( response => {
                dispatch(changePass(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const GetProfile = () => {
    return dispatch => {
        axios.get( 'profile')
            .then( response => {
                dispatch(getProfile(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};