import axios from "../../../axios-config";
import * as actionTypes from "./actionTypes";

export const login = (data) => {
    return {
        type: actionTypes.LOGIN,
        data: data
    };
};

export const logout = (data) => {
    return {
        type: actionTypes.LOGOUT,
        data: data
    };
};

export const qrCam = (data) => {
    return {
        type: actionTypes.QR_CAM,
        data: data
    };
};

export const qrImage = (data) => {
    return {
        type: actionTypes.QR_IMAGE,
        data: data
    };
};

export const Login = (data) => {
    return dispatch => {
        axios.post( 'login', data)
            .then( response => {
                dispatch(login(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const Logout = () => {
    return dispatch => {
        axios.post( 'logout')
            .then( response => {
                dispatch(logout(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const QRCam = (data) => {
    return dispatch => {
        axios.post( 'qr-finder', data)
            .then( response => {
                dispatch(qrCam(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const QRImage = (file) => {
    let data = new FormData();
    data.append('image', file);
    // alert(data);
    return dispatch => {
        axios.post( 'qr-upload', data)
            .then( response => {
                dispatch(qrImage(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};