import axios from "../../../axios-config";
import * as actionTypes from "./actionTypes";

export const checkAccount = (data) => {
    return {
        type: actionTypes.CHECK_ACCOUNT,
        data: data
    };
};

export const sendOtp = (data) => {
    return {
        type: actionTypes.SEND_OTP,
        data: data
    };
};

export const sendWallet = (data) => {
    return {
        type: actionTypes.SEND_WALLET,
        data: data
    };
};

export const CheckAccount = (data) => {
    return dispatch => {
        axios.post( 'recipient-details', data)
            .then( response => {
                dispatch(checkAccount(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const SendOTP = (data) => {
    return dispatch => {
        axios.post( 'otp', data)
            .then( response => {
                dispatch(sendOtp(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};

export const SendWallet = (data) => {
    return dispatch => {
        axios.post( 'fund-transfer', data)
            .then( response => {
                dispatch(sendWallet(response.data));
            } )
            .catch( error => {
                console.log(error);
            } );
    };
};