import React, {Component} from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol, IonTextarea, IonText,IonCardSubtitle,IonCardTitle,IonCardContent,
    IonButton, IonAlert, IonButtons, IonAvatar, IonModal, IonItem, IonLabel, IonInput, IonList, IonToast, IonCard, IonCardHeader
} from '@ionic/react';
import {connect} from 'react-redux';
import * as action from "../services/store/auth/actions";
import * as actions from "../services/store/profile/actions";
import * as homeAcction from "../services/store/home/actions";
import SimpleReactValidator from "simple-react-validator";
import parse from 'html-react-parser';

interface myState {
    showLogout: boolean,
    showModalProfile: boolean,
    showModalPass: boolean,
    resMessage: object[],
    toast: boolean,
    toast_success: boolean,
    profileData: {
        email: string,
        barcode: string,
        first_name: string,
        middle_initial: string,
        last_name: string,
        birth_date: string,
        gender: string,
        phone_number: string,
        address: string,
        barangay: string,
        city: string,
        state: string,
        image: string,
        created_at: string,
        renewal: object
    },
    password: string,
    new_password: string,
    confirm_password: string,
    showChangePassModal: boolean,
    showModalQR: boolean,
    showModalTos: boolean
}

class Settings extends Component<any, myState> {
    private validator: SimpleReactValidator;

    constructor(props: any) {
        super(props);
        this.state = {
            showLogout: false,
            showModalProfile: false,
            showModalPass: false,
            resMessage: [],
            toast: false,
            toast_success: false,
            profileData: {
                email: '',
                barcode: '',
                first_name: '',
                middle_initial: '',
                last_name: '',
                birth_date: '',
                gender: '',
                phone_number: '',
                address: '',
                barangay: '',
                city: '',
                state: '',
                image: '',
                created_at: '',
                renewal: []
            },
            password: '',
            new_password: '',
            confirm_password: '',
            showChangePassModal: false,
            showModalQR: false,
            showModalTos: false
        }

        this.validator = new SimpleReactValidator({
            element: (message: any) => <IonText color="danger">{message}</IonText>
        })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.resMessage !== this.props.resMessage) {
            if (this.props.resMessage[0] === 'successfully logged out') {
                localStorage.clear();
                window.location.replace("/login");
            } else {
                this.setState({
                    resMessage: this.props.resMessage,
                })
            }
        }

        if (prevProps.resMessageChangePass !== this.props.resMessageChangePass) {
            if (this.props.resMessageChangePass) {
                if (this.props.resMessageChangePass[0] === 'Password Successfully Changed.') {
                    this.setState({
                        resMessage: this.props.resMessageChangePass,
                        toast_success: true
                    })
                    setTimeout(() => {
                        localStorage.clear();
                        window.location.replace("/login");
                    }, 4000)
                } else {
                    this.setState({
                        resMessage: this.props.resMessageChangePass,
                        toast: true
                    })
                }
            }

        }

        if (prevProps.profileData !== this.props.profileData) {
            this.setState({
                profileData: this.props.profileData
            })
        }
    }

    componentDidMount(): void {
        this.props.GetProfile();
        this.props.TosRead();
    }

    passwordChange = (event: any) => {
        this.setState({password: event.detail.value});
    };

    newPassChange = (event: any) => {
        this.setState({new_password: event.detail.value});
    };

    confirmPassChange = (event: any) => {
        this.setState({confirm_password: event.detail.value});
    };

    logout = () => {
        return (
            <IonAlert
                isOpen={this.state.showLogout}
                onDidDismiss={() => this.setState({showLogout: false})}
                header={'Log Out'}
                message={'Are you sure you want to Log out?'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            console.log('Confirm Cancel: blah');
                        }
                    },
                    {
                        text: 'Okay',
                        handler: () => {
                            this.props.Logout();
                        }
                    }
                ]}
            />
        )
    }

    changePass = () => {
        return (
            <IonAlert
                isOpen={this.state.showChangePassModal}
                onDidDismiss={() => this.setState({showChangePassModal: false})}
                header={'Change Password'}
                message={'Are you sure you want to change your password?, you\'ll be redirected to login page after successful transaction.'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            console.log('Confirm Cancel: blah');
                        }
                    },
                    {
                        text: 'Okay',
                        handler: () => {
                            this.props.ChangePass(this.state);
                        }
                    }
                ]}
            />
        )
    }

    submitHandler = (e: any) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({showChangePassModal: true})
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    getRenewalHistory() {
        let result: any[] = [];
        if (this.state.profileData.renewal instanceof Array) {
            this.state.profileData.renewal.map((x: any,i: number) => {
                result.push(
                    <IonCard key={i} style={{width:'100%'}}>
                        <IonCardHeader style={{width:'100%'}}>
                            <IonCardSubtitle>Expiration Date: {x.expiration_date}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent  style={{width:'100%'}}>
                            Renewal Date: {x.renew_date} | Remaining Days: {x.remaining_days} | {x.comments}
                        </IonCardContent>
                    </IonCard>
                )
            })
        }
        return result;
    }

    render() {
        this.validator.purgeFields();

        const errorMessage = () => {
            let data = this.state.resMessage;
            let res = '';
            let length = data ? data.length : 0;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    res += "<li>" + data[i] + "</li>"
                }
                return <IonToast
                    isOpen={this.state.toast}
                    color="danger"
                    position="top"
                    onDidDismiss={() => this.setState({toast: false})}
                    message={res}
                    duration={5000}
                />;
            }
        }

        const successMessage = () => {
            let data = this.state.resMessage;
            let res = '';
            let length = data ? data.length : 0;
            if (length !== 0) {
                for (let i = 0; i < length; i++) {
                    res += "<li>" + data[i] + "</li>"
                }
                return <IonToast
                    isOpen={this.state.toast_success}
                    color="success"
                    position="top"
                    onDidDismiss={() => this.setState({toast_success: false})}
                    message={res}
                    duration={5000}
                />;
            }
        }

        const QRCodeGenerator = (barcode: any) => {
            let QRCode = require('qrcode.react');
            return (
                <QRCode value={barcode} renderAs="canvas" size={300}/>
            )
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    {errorMessage()}
                    {successMessage()}
                    {this.logout()}
                    {this.changePass()}
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton expand="block" fill="outline" shape="round"
                                           onClick={() => this.setState({showModalProfile: true})}>View
                                    Profile</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand="block" fill="outline" shape="round"
                                           onClick={() => this.setState({showModalQR: true})}>Your QR Code</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand="block" fill="outline" shape="round"
                                           onClick={() => this.setState({showModalPass: true})}>Change
                                    Password</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand="block" fill="outline" shape="round"
                                           onClick={() => this.setState({showModalTos: true})}>Terms Of Service</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand="block" fill="outline" shape="round"
                                           onClick={() => this.setState({showLogout: true})}>Log Out</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonModal isOpen={this.state.showModalProfile}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Profile</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton
                                        onClick={() => this.setState({showModalProfile: false})}>Close</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol className="ion-text-center ion-margin-top ion-margin-bottom">
                                        <IonAvatar style={{margin: "0 auto", width: "150px", height: "150px"}}>
                                            <img alt="profile_image"
                                                 src={this.state.profileData.image}/>
                                        </IonAvatar>
                                        <br/>
                                        <IonList lines="none">
                                            <IonItem>
                                                <IonLabel position="floating">First Name</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.first_name}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Middle Initial</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.middle_initial}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Last Name</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.last_name}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Birth Date</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.birth_date}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Gender</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.gender}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Email</IonLabel>
                                                <IonInput type="text"
                                                          value={this.state.profileData.email ? this.state.profileData.email : 'N/A'}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Phone Number</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.phone_number}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Province</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.state}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">City</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.city}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Barangay</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.barangay}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Address</IonLabel>
                                                <IonTextarea value={this.state.profileData.address}
                                                             readonly></IonTextarea>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Account Created</IonLabel>
                                                <IonInput type="text" value={this.state.profileData.created_at}
                                                          readonly></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position="floating">Account Renewal History</IonLabel>
                                                <IonRow class="ion-margin-top">
                                                    <IonCol size="12">
                                                        {this.getRenewalHistory()}
                                                    </IonCol>
                                                </IonRow>
                                            </IonItem>
                                        </IonList>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>

                        </IonContent>
                    </IonModal>

                    <IonModal isOpen={this.state.showModalPass}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Change Password</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => this.setState({showModalPass: false})}>Close</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonList>
                                            <IonItem>
                                                <IonLabel position="floating">Current Password</IonLabel>
                                                <IonInput type="password" onIonChange={this.passwordChange}
                                                          value={this.state.password}></IonInput>
                                            </IonItem>
                                            {this.validator.message('Current Password', this.state.password, 'required')}
                                            <IonItem>
                                                <IonLabel position="floating">New Password</IonLabel>
                                                <IonInput type="password" onIonChange={this.newPassChange}
                                                          value={this.state.new_password}></IonInput>
                                            </IonItem>
                                            {this.validator.message('New Password', this.state.new_password, 'required')}
                                            <IonItem>
                                                <IonLabel position="floating">Confirm Password</IonLabel>
                                                <IonInput type="password" onIonChange={this.confirmPassChange}
                                                          value={this.state.confirm_password}></IonInput>
                                            </IonItem>
                                            {this.validator.message('Confirm Password', this.state.confirm_password, 'required')}
                                        </IonList>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                        <IonButton expand="block" shape="round" onClick={this.submitHandler}>Change Password</IonButton>
                    </IonModal>

                    <IonModal isOpen={this.state.showModalQR}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>QR Code</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => this.setState({showModalQR: false})}>Close</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonGrid>
                                <IonRow style={{backgroundColor:'#FFFFFF'}}>
                                    <IonCol className="ion-text-center ion-padding">
                                        <IonLabel position="floating" className="mb-5" style={{color:'#000000'}}>Scan to receive wallet
                                            load</IonLabel>
                                        <br/><br/>
                                        {QRCodeGenerator(this.state.profileData.barcode)}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                    </IonModal>

                    <IonModal isOpen={this.state.showModalTos}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Terms Of Service</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => this.setState({showModalTos: false})}>Close</IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        {parse(this.props.tos)}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                    </IonModal>
                </IonContent>
            </IonPage>
        );
    }
};

const mapStateToProps = (state: any) => {
    return {
        resMessage: state.auth.resMessage,
        profileData: state.profile.profileData,
        resMessageChangePass: state.profile.resMessage,
        tos: state.home.tosData.tos,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        Logout: () => dispatch(action.Logout()),
        GetProfile: () => dispatch(actions.GetProfile()),
        ChangePass: (data: any) => dispatch(actions.ChangePass(data)),
        TosRead: () => dispatch(homeAcction.TosRead()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
